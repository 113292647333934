/*------head-----------*/
.head {
  font-family: 'Roboto', sans-serif;
  padding: 6px 0px;
  color: #4D6B4A;
  background-color: white;
}

.head label{
  font-size: 16px;
}

.right{
  display: flex;
  align-items: center;
  gap: 10px;
}

.right label{
  color: #000;
}

.right label a{
  color: #4D6B4A;
}

.head span{
  color: #DC6E00;
}

.head img{
  height: 35px;
  margin-left: 10px;
}

/*------head-----------*/

/*-----Navbar-------*/
.navColor{
  background-color: rgba(62, 88, 59, 0.9);
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  
  color: white;
}

.logo img {
  max-width: 80px;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links a {
  text-decoration: none;
  color: white;
  transition: color 0.3s;
}

.nav-links a:hover,
.nav-links a.active {
  color: #ffc107;
}

.mobile-menu-icon {
  display: none;
  cursor: pointer;
  font-size: 24px;
  z-index: 1000;
  transition: transform 0.3s;
}

.mobile-menu-icon.open {
  transform: rotate(90deg);
}
 

@media (max-width: 768px) {

  .head .right{
    display: none;
  }

  .head .d_flex{
    justify-content: center;
  } 
  .nav-links {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 50px;
      right: 10px;
      background: #215541;      
      width: 180px;      
      padding: 40px ;
      border-radius: 3px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
      z-index: 999;
    }
  
    .nav-links.active {
      display: flex;
    }
  
    .mobile-menu-icon {
      display: block;
    }


}
@media (max-width: 435px) {
.head{
  display: none;
}

}
