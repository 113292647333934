#contact {
    padding: 80px 0 20px;
}

.map-part {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0 40px;
}

.map-part iframe {
    width: 90%;
    height: 400px;
    box-shadow: rgb(43 52 69 / 10%) 0px 6px 18px;
}

.contactData {
    position: relative;
    min-width: 1100px;
    min-height: 550px;
    display: flex;
    z-index: 100;
    margin-bottom: 40px;
}

.contactData .contactInfo {
    position: absolute;
    top: 40px;
    width: 360px;
    height: calc(100% - 80px);
    background: #4D6B4A;
    z-index: 1;
    padding: 40px 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 20px 25px rgba(0, 0, 0, 0.15);
}

.contactData .contactInfo h2 {
    color: #fff;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
}

.contactData .contactInfo ul.info {
    position: relative;
    margin: 20px 0;
}

.contactData .contactInfo ul.info li {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin: 20px 0;
    cursor: pointer;
}

.contactData .contactInfo ul.info li span:nth-child(1) {
    width: 30px;
    min-width: 30px;
}

.contactData .contactInfo ul.info li span:nth-child(1) img {
    max-width: 100%;
    filter: invert(1);
}

.contactData .contactInfo ul.info li span:nth-child(2) {
    color: #fff;
    margin-left: 10px;
    font-weight: 300;
}

.contactData .contactInfo ul.sci {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
}

.contactData .contactInfo ul.sci li a img {
    filter: invert(1);
    width: 30px;
    height: auto;
}

.contactData .contactForm {
    position: absolute;
    padding: 70px 50px;
    padding-left: 250px;
    margin-left: 150px;
    width: calc(100% - 150px);
    height: 100%;
    box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.10);
}

.contactData .contactForm h2 {
    color: #0f3959;
    font-size: 24px;
    font-weight: 500;

}

.contactData .contactForm .formbox {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 30px;
}

.contactData .contactForm .formbox .inputBox {
    position: relative;
    margin-bottom: 35px;
}

.contactData .contactForm .formbox .inputBox.w50 {
    width: 47%;
}

.contactData .contactForm .formbox .inputBox.w100 {
    width: 100%;
}

.contactData .contactForm .formbox .inputBox input,
.contactData .contactForm .formbox .inputBox textarea {
    width: 100%;
    resize: none;
    padding: 5px 0;
    font-size: 16px;
    font-weight: 300;
    color: #333;
    border: none;
    outline: none;
    border-bottom: 1px solid #777;
}

.contactData .contactForm .formbox .inputBox textarea {
    height: 120px;
}

.contactData .contactForm .formbox .inputBox span {
    position: absolute;
    left: 0;
    padding: 5px 0;
    pointer-events: none;
    font-size: 16px;
    font-weight: 300;
    transition: 0.3s;
}

.contactData .contactForm .formbox .inputBox input:focus ~ span,
.contactData .contactForm .formbox .inputBox input:valid ~ span,
.contactData .contactForm .formbox .inputBox textarea:focus ~ span,
.contactData .contactForm .formbox .inputBox textarea:valid ~ span {
    transform: translateY(-20px);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #4D6B4A;
    font-weight: 500;
}

.contactData .contactForm .formbox .inputBox button {
    position: relative;
    cursor: pointer;
    background: #4D6B4A;
    color: #fff;
    border: none;
    width: 200px;
    padding: 12px;
}

.contactData .contactForm .formbox .inputBox button:hover {
    background: #0d9480;
}

@media (max-width : 1200px) {
    .contactData {
        width: 90%;
        min-width: auto;
        margin: 20px;
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
    }

    .contactData .contactInfo {
        top: 0;
        height: 500px;
        position: relative;
        box-shadow: none;
    }

    .contactData .contactForm {
        position: relative;
        width: calc(100% - 350px);
        padding-left: 0;
        margin-left: 0;
        padding: 40px;
        height: 550px;
        box-shadow: none;
    }

    @media (max-width: 991px) {
        .contactData {
            display: flex;
            flex-direction: column;
        }
    }

    .contactData .contactForm,
    .contactData .contactInfo{
        width: 100%;
        height: auto;
    }

    /* .contactData .contactInfo {        
        flex-direction: row;
    } */

    .contactData .contactInfo ul.sci {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

@media (max-width : 600px) {
    .contactData .contactForm {
        padding: 20px;
    }

    .contactData .contactInfo {
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
    }

    .contactData .contactInfo ul.sci {
        margin-top: 40px;
    }

    .contactData .contactForm .formbox .inputBox.w50 {
        width: 100%;
    }

}

@media (max-width: 425px) {

    #contact {
        padding: 40px 0 ;
    }

    .contactData {
        width: 100%;
        margin: 10px auto;
    }

    .contactData .contactInfo ul.info li span:nth-child(2){
        font-size: 12px;
    }

    .map-part iframe {
        width: 100%;
        height: 300px;
        box-shadow: rgb(43 52 69 / 10%) 0px 6px 18px;
    }
    
}