.homeProduct{
  padding: 20px 0 0;
  overflow: hidden;
}

.homeSlideMain h1 {
  font-size: 40px;
  line-height: 50px;
  margin: 20px 0;
}
.homeSlideMain p {
  margin: 20px 0;
  text-align: justify;
}
.homeProduct .homeSlideMain ul {
  position: absolute;
  bottom: -40px;
}
.homeSlideMain ul li {
  border: 2px solid #b8b7b7;
  border-radius: 50%;
  padding: 10px;
}
.homeSlideMain ul li button {
  background: transparent !important;
  color: transparent !important;
}
.homeSlideMain ul li button::before {
  font-size: 15px !important;
  color: #0f3460 !important;
  position: absolute;
  top: 2px;
  left: 0;
  margin: auto;
}

.homeSlideMain .left{
  margin-right: 20px;
  width: 50%;
}

.homeSlideMain .right img{
  max-height: 250px;
  width: auto;
  margin-right: 15px;
}

.homeSlideMain .top{
  margin-top: 70px;
  padding-bottom: 20px;
  flex-wrap: nowrap;
}
@media (max-width: 1024px) {
  .homeSlideMain.contentWidth{
    height: 100%;
  }

  .homeSlideMain .top{
    margin-top: 20px;
    padding-bottom: 20px;
  }
}
@media (max-width: 768px) {


.homeSlideMain h1 {
  font-size: 32px;
  line-height: 36px;
}

.homeSlideMain .SliderRight img {
  max-height: 160px;
}



}

@media (max-width: 650px) {

.homeSlideMain .top {
  flex-direction: column-reverse;
  justify-content: center;
}

.homeSlideMain .left{
  margin-right: 00px;
  width: 100%;
}


.homeSlideMain h1 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin: 20px 0 0 0;
}
}

@media (max-width: 435px) {

  .homeProduct .homeSlideMain ul {
    display: none;
  }

  .homeSlideMain .right img{
    max-height: 180px;
  }  

}