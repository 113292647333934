.homecolor {
  background: linear-gradient(180deg, rgba(77, 107, 74, 0.50) 0%, rgba(77, 107, 74, 0.79) 29.08%, rgba(77, 107, 74, 0.80) 50%, #000B01 95%), url("../../assests/homeMain.jpg") center center no-repeat;
  background-size: cover;
  height: 700px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homemain {
  display: flex;
  align-items: center;
  justify-content: center;
}


.HomeSlideBox {
  color: white;
}

.homeSlide h1 {
  margin: 20px 0;
  font-family: "Roboto", sans-serif;
  font-size: 60px;
  font-weight: 800;
  line-height: 72px;
  letter-spacing: 3.54px;
}

.homeSlide p {
  margin: 10px 0 20px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 20px;
  letter-spacing: 1.5px;
  text-align: left;
}

.slick-prev {
  left: -80px;
}

.slick-next {
  right: -80px;
}

.homecolor .homeSlide ul {
  display: none !important;
}


.homeSlide .left {
  width: 60%;
  padding-left: 30px;
}

.homeSlide .SliderRight img {
  max-height: 400px;
  width: auto;
  margin-right: 15px;
}

.homeSlide .top {
  margin-top: 20px;
  padding-bottom: 20px;
}


.homeSlide .control-btn .prev {
  left: -40px;
}
.homeSlide .control-btn .next {
  right: -40px;
}

@media (max-width: 1024px) {
  .homecolor {
    display: block;
    padding-top: 60px;
    height: 640px;
  }
  .homeSlide{
    width: 100%;
    height: 300px;
  }
  .control-btn{
    display: none;
  }

  .homeSlide .left {
    padding-left: 0px;
  }

  .homeSlide .SliderRight img {
    max-height: 240px;
}
}

@media (max-width: 768px) {

  .homeSlide h1 {
    font-size: 28px;
    line-height: 34px;
  }

  .homeSlide p {
    margin: 0px ;
    font-size: 14px;
    letter-spacing: 1px;
    margin-bottom: 15px;
  }


}

@media (max-width: 650px) {

  .homeSlide .top {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .homeSlide .left {
    margin-right: 0px;
    width: 100%;
    text-align: center;
  }

  .homeSlide p {
    text-align: center;
}

}

@media (max-width: 425px) {

  .homeSlide{
    height: 100%;
  }

  .homeSlide .SliderRight img {
    max-height: 180px;
  }

}

@media (max-width: 320px) {
  .homeSlide h1 {
    font-size: 22px;
    line-height: 27px;
}

.homecolor {
  padding-top: 40px;
  height: 600px;
  background-position: left;
}

.homeSlide .container {  
  padding: 0px 10px;
}
}  