#about {
    padding: 80px 0 20px;
}

#about .about-content {
    display: flex;
    flex-wrap: wrap-reverse;
    /* align-items: flex-start; */
    justify-content: space-evenly;
}

#about .about-content .about2-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 45%;
    height: auto;
}


#about .about-content-2 .about2-right img {
    width: 100%;
    height: 330px;
    margin-bottom: 30px;
}

.about-content h2 {
    color: #30373E;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 44px;
    font-weight: 700;
    line-height: 52px;
}

.about-content p {
    color: #4D6B4A;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
}

#about .about-content .about2-left {
    width: 40%;
}

#about .about-content .left img {
    height: 600px;
    width: 100%;
    object-fit: cover;
    position: relative;
}

#about-data {
    width: 90%;
    margin: 20px auto 60px;
}

#about-data .data-1 {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: rgb(48, 55, 62);
    text-align: justify;
}

#about-data .data-2 {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
    line-height: 24px;
    color: rgb(48, 55, 62);
    text-align: center;
    margin-top: 20px;
}

.about-customer {
    background-color: #4D6B4A;
    color: #DCD194;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px 10px;
}

.customer-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    max-width: 250px;
    text-align: center;
}

.customer-box i {
    font-size: 54px;
    padding: 10px;
}

.customer-box h3 {
    font-size: 24px;
    font-weight: 600;
}

.customer-box p {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}

.founder-about {
    padding: 80px 0;
}

.founder-about .heading h1 {
    color: #222;
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 1.44px;
    text-transform: uppercase;
    position: relative;
}

.founder-about .heading h1::before {
    content: "WHO WE ARE";
    position: absolute;
    bottom: 0;
    color: rgba(146, 140, 140, 0.30);
    text-align: center;
    font-family: "Cookie", cursive;
    font-size: 72px;
    font-weight: 500;
    letter-spacing: 6px;
    margin-left: -40px;
}

/* About Text */
.my-about-container {
    margin: 60px auto 20px;
    width: 90%;
}

.about-width-1 {
    width: 55%;
}

.about-width-2 {
    width: 40%;
}

.about-row {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 10px;
}

.my-about-text p {
    margin-right: 20px;
    text-align: justify;
}


.my-about-sub-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 60%;
}

.my-about-line {
    width: 64px;
    height: 5px;
    border-bottom: 2px solid #4D6B4A;
    margin-right: 10px;
}

.my-about-sub-title p {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.1em;
    color: #4D6B4A;
}

.my-about-title {
    margin-top: 10px;
}

.my-about-title p {
    font-family: "Cookie", cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    color: #2c2c2c;
}

.text p {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    color: #4D6B4A;
}

.my-about-image {
    display: flex;
    justify-content: center;
}

.my-about-image img {
    width: 360px;
    height: 450px;
    border-radius: 3px;
    object-fit: cover;
}


@media (max-width:768px) {
    .about-row {
     flex-direction: column;
     gap: 20px;
    }

    .about-row-2 {
      flex-direction: column-reverse;
    }

    .about-width-1,
    .about-width-2,
    .my-about-sub-title {
        width: 100%;
    }

    .my-about-title p{
        font-size: 44px;
    }

    .my-about-text p {
        margin-right: 0px;
        font-size: 14px;
    }

    .founder-about .heading h1{
        font-size: 24px;
    }

    .founder-about .heading h1::before {      
        font-size: 44px;
        letter-spacing: 4px;
        margin-left: -30px;
    }

    #about .about-content-2 .about2-right img {
        height: 240px;
        margin-bottom: 20px;
    }

    #about .about-content .left img {
        height: 400px;
    }

    .about-content h2 {
        font-size: 28px;
        line-height: 34px;
    }

    .about-content p,
    .customer-box p{
        font-size: 14px;
    }

    .customer-box{
        gap: 5px;
    }

    #about-data .data-1 {
        font-size: 16px;
        line-height: 24px;
    }

    #about-data .data-2 {
        font-size: 14px;
        line-height: 22px;
    }

}

@media (max-width:425px) {
    .my-about-image img {
        width: 280px;
        height: 360px;
    }
    #about .about-content {
        flex-direction: column-reverse;
        gap: 30px;
        align-items: center;
    }

    #about .about-content .about2-right,
    #about .about-content .about2-left {
        width: 90%;
    }



    #about-data .data-1,
    #about-data .data-2 {
        font-size: 14px;
        line-height: 22px;
    }
}

@media (max-width:375px) {

    #about .about-content-2 .about2-right img {
        height: 200px;
    }

    .about-content h2 {
        font-size: 22px;
        line-height: 26px;
    }

    #about .about-content .left img {
        height: 300px;
    }

}