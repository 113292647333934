 .loading {
    width: 100vw;
    height: 100vh;
    background-color: #4D6B4A;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
    max-width: 100%;
}
  
  .loading > div {
    width: 8vmax;
    height: 8vmax;
    border-bottom: 5px solid rgba(255, 255, 255, 0.719);  
    border-radius: 50%;  
    animation: loadingRotate 800ms linear infinite;
  }
  .imgg{
    animation: fadeIn 2000ms ease-in-out;
  }
  
  @keyframes loadingRotate {
    to {
      transform: rotateZ(-360deg);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.4;
    }
  }