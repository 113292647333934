.PopularProduct{
    background: #4D6B4A;
    color: white;
    padding: 40px 0px;
}

.heading-common.head-popular h1::before{
    content: url('../../assests/bgs/2.png');    
    position: absolute;
    right: -50px;
    top: -30px;
    z-index: -1;
}

.flash{
    padding: 30px 0;
}

@media (max-width: 435px) {

    .PopularProduct .container{
        padding: 0 10px;
    }

    .heading-common.head-popular h1::before {
        top: -30px;
        right: -20px;
    }

    
}