* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
}

.container {
  max-width: 1280px;
  margin: auto;
  padding: 0 20px;
}

.d_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c_flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}


.e_flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.g_flex {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

a {
  text-decoration: none;
  color: black;
}

li {
  list-style: none;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.RText {
  text-align-last: right;
}

input,
button {
  border: none;
  outline: none;
  background-color: none;
}

.row {
  width: 50%;
}

.icon-circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #f3f5f9;
  margin-left: 20px;
  text-align: center;
  border-radius: 50%;
}

.boxShadow {
  box-shadow: rgb(43 52 69 / 10%) 0px 4px 16px;
}

.gold {
  color: #ffc107;
}

.heading-common h1 {
  color: #FFF;
  text-align: justify;
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 1px;
  display: inline-block;
  position: relative;
  z-index: 0;
}

/*---------category----------*/
.contentWidth {
  width: 74%;
  height: 520px;
  background-color: white;
  box-shadow: rgb(43 52 69 / 15%) 0px 6px 16px;
}

.category {
  box-shadow: rgb(43 52 69 / 10%) 0px 6px 16px;
  background-color: #fff;
  width: 23%;
  min-height: 520px;
  padding: 10px 0;
  border-radius: 5px;
}

.category .box {
  margin: 0 20px;
  padding: 10px ;
  border-bottom: 1px solid #d8d8db;
  display: block;
  transition: all ease 0.3s;
}


.category span {
  text-transform: capitalize;
  font-size: 18px;
}



.category .box:hover {
  background-color: #f1c05ebd;
  cursor: pointer;
}

/*---------category----------*/
.btn-primary {
  display: inline-block;
  background: rgba(220, 110, 0, 1);
  padding: 12px 30px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
}

.top {
  margin-top: 80px;
}

.mtop {
  margin-top: 40px;
}

.ptype,
.discount {
  position: absolute;
  top: 0;
  background: #15ed27;
  padding: 3px 10px;
  font-size: 9px;
  border-radius: 30px;
  color: #fff;
  margin: 10px;
  z-index: 10;
}

.discount {
  left: 0;
}

.ptype {
  right: 0;
  background: #0f3460;
}

/*---------product-----------*/
.NewArrivals .arrival .box {
  width: 170px;
  margin: 10px 0;
}

.NewArrivals.Discount .product {
  width: 180px;
  margin: 20px auto 0;
}

.product {
  border-radius: 10px;
  background-color: white;
  padding: 20px;
  position: relative;
  box-shadow: rgba(218, 217, 247, 0.09) 0px 1px 3px;
  margin: 10px;
}
.product h4 {
  font-size: 12px;
  font-weight: 400;
}

.product h3 {
  font-weight: 600;
  font-size: 16px;
  color: #0f3460;
}

.product .rate i {
  font-size: 15px;
  color: #ffcd4e;
  margin: 5px 5px 5px 0;
}


.product .price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #003F62;
  margin-top: 10px;
}

.product .price .LinkNumber {
  background: #003F62;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 3px;
  padding: 5px 10px;
  text-decoration: none;
  transition: all ease 0.3s;
}

.product .price .LinkNumber:hover {
  background: #ffc107;
}

.price button {
  color: white;
  background: #003F62;
  font-size: 20px;
  transition: 0.5s;
  border: 1px solid rgba(230, 229, 247, 0.09);
  width: 35px;
  height: 35px;
  border-radius: 5px;
}

.price button:hover {
  cursor: pointer;
  background-color: #EDA415;
  color: #fff;
}


.ProductCard{
  /* border: 0.5px solid #c0c0c0;   */
  min-height: 400px;
  max-width: 250px;
}

.ProductCard.green{
  background-color: #4D6B4A;
}
.ProductCard.yellow{
  background-color: #DCD194;
}

.ProductCard h3{
  min-height: 50px;
}

.ProductCard h3,
.ProductCard h4{
  color: white;
}


/*---------product-----------*/
/*----- control btn -------*/
.control-btn .next,
.control-btn .prev {
  position: absolute;
  top: 40%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: none;
  color: #4D6B4A;
  border: 2px solid white;
  border-radius: 50%;
  transform: 0.5s;
  z-index: 5;
}

.control-btn .prev {
  left: -20px;
}

.control-btn .next {
  right: -20px;
}

.control-btn .prev i,
.control-btn .next i {
  font-size: 20px;
  transition: 0.5s;
  color: white;
}

.control-btn .prev:hover,
.control-btn .next:hover {
  background: #EDA415;
  color: white;
  cursor: pointer;
  border: none;
}

/* Hide the next arrow button */
.flash .slick-next,
#TopCate .slick-next {
  display: none !important;
}

/* Hide the previous arrow button */
.flash .slick-prev,
#TopCate .slick-prev {
  display: none !important;
}

/*----- control btn -------*/
/*----- heading -------*/
.heading {
  margin-bottom: 30px;
}

.NewArrivals .heading .heading-left,
.flash .heading .heading-left{
  display: flex;
  align-items: center;
  gap: 5px;
}

.heading .heading-left h2 {
  font-size: 25px;
  margin: 5px;
  color: #003F62;
}

.heading .heading-right i,
.heading .heading-right {
  text-align: right;
  color: white;
  cursor: pointer;
}

.heading .heading-right i {
  margin: 10px;
}

.heading .heading-right {
  padding: 5px 15px;
  background-color: #003F62;
  border-radius: 30px;
}

/*----- heading -------*/
.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;
}

.grid2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}


/* card css  */
.shop {
  min-height: 70vh;
  width: 100%;
}

.shopHeading {
  text-align: center;
  padding-bottom: 30px;
  border-bottom: 1px solid #928c8cc7;
  margin: 40px auto;
  width: 100%;
}

.shopHeading h1 {
  color: #061424;
  padding-bottom: 5px;
  border-bottom: 4px solid #4D6B4A;
  font-weight: 600;
  text-align: center;
}

.shop .category {
  padding: 30px;
  height: 90vh;
}

.chead h1 {
  font-size: 20px;
  margin-bottom: 20px;
}

.chead h1:nth-child(2) {
  opacity: 0.5;
  border-left: 3px solid rgba(0, 0, 0, 0.5);
  padding-left: 70px;
}

.shop .category .box {
  background-color: #f6f9fc;
  margin: 15px 0;
  padding: 5px 10px;
  transition: 0.5s;
  border-radius: 5px;
}

.shop .category img {
  width: auto;
  height: auto;
}

.shop .category .box:hover {
  background-color: #fff;
  box-shadow: rgb(43 52 69 / 10%) 0px 4px 16px;
}

.shop .box2 {
  margin-top: 100px !important;
  text-align: center;
}

.shop .category button {
  padding: 10px;
  background: none;
  text-align: center;
  font-size: 17px;
  font-weight: 500;
}

.shop .contentWidth {
  margin-left: 30px;
}

.grid1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.shop .product .lazy-image {
  width: 200px;
  height: 200px;
  object-fit: contain;
  margin: 10px auto ;
  cursor: pointer;
}

.shop .product {
  transition: 0.5s;
}

.shop .product:hover {
  box-shadow: rgb(43 52 69 / 10%) 0px 4px 16px;
}

.shop .product-content {
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.shop .product-content .box {
  max-width: 280px;
}

/* //popular product card */
.PopularProductCard .lazy-image {
  height: 160px;
  object-fit: contain;
  margin: 10px auto 0;
}

.PopularProductCard h3 {
  font-weight: 600;
  font-size: 15px;
  color: #061424;
}

.PopularProductCard h4 {
  color: #0f3460;
  font-size: 12px;
  font-weight: 500;
}

.PopularProductCard .price .LinkNumber {
  font-size: 14px;
  border-radius: 30px;
  padding: 5px 15px;
}

/* //category product  */
.CatProduct {
  padding: 40px 0;
  background: #000B01;
  margin-top: -1px;
}

.ProductPage{
  background-color:  #F4F4F5;
}


@media (max-width: 1024px) {

  .category {
    display: none;
  }

  .contentWidth {
    width: 100%;
  }

}

@media (max-width: 435px) {

  .heading .heading-left h1 {
    font-size: 20px;
  }

  .heading-common h1 {
    font-size: 28px;
  }

  .heading .heading-right {
    padding: 5px 10px;
  }

  .heading .heading-right span {
    font-size: 12px;
  }

  .heading .heading-right i {
    display: none;
  }

}