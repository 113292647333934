footer {
  background-color: rgb(52,64,64);
  padding: 40px 0;
  color: #fff;
}

footer .img {  
  max-width: 100px;
  margin-bottom:10px;
}

footer p {
  font-size: 14px;
  font-weight: 300;
  opacity: 0.8;
  margin-bottom: 20px;
  text-align: justify;
  padding-right: 20px;
}

footer li {
  margin-bottom: 5px;
  opacity: 0.8;
  cursor: pointer;
}

footer li .link{
  color: white;
  font-size: 15px;
} 


.footer_last{
  /* text-align: center; */     
  padding: 10px 0px;      
  color: #fff;
  background-color: rgba(77, 107, 74, 1);
  border-top: 1px solid #ffffffa9;
} 

.footer_Data{
  display: flex;
  align-items: center;
  justify-content: space-between; 
}

.footer_last a{
  color: #ffc107 ;
  cursor: pointer;
}

footer .about{
  width: 340px;
}

@media (max-width: 1024px) {
  footer .about {
    width: 320px;
}
}

@media (max-width: 768px) {
  .footer_Data{
    flex-direction: column;
    justify-content: center;
  }
 
  footer .about {
    width: 100%;
}
 

}

@media (max-width: 435px) {

  footer{
    padding: 40px 0;
  }

  .footer_Data{
    font-size: 12px;
    text-align: center;
  }


footer li,
footer li .link {
  font-size: 13px;
}

.footer_last .rights{
  text-align: center;
}

}