#TopCate {
  overflow: hidden;
  position: relative;
  background: #000B01;
  padding: 60px 0 40px;
  margin-top: -1px;
}

#TopCate img {
  border-radius: 5px;
}

#TopCate .nametop {
  position: relative;
  width: 100%;
}

#TopCate .nametop span {
  background: #0f3460;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 50px;
  margin: 5px;
  position: absolute;
  top: 10px;
}

#TopCate .nametop .tleft {
  left: 0;
}

#TopCate .nametop .tright {
  background-color: aliceblue;
  color: black;
  position: absolute;
  right: 0;
}

.heading-top h1::before {
  content: url('../../assests/bgs/3.png');
  position: absolute;
  right: -50px;
  top: -90px;
  z-index: -1;
}

.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 20px;
  min-height: 300px;
  border-radius: 5px;
  background-color: #DCD194;
  margin: 20px;
}

.testimonial .rate i {
  font-size: 18px;
  color: #EDA415;
  margin: 5px;
}

.testimonial p {
  text-align: center;
  font-weight: 500;
}

.testimonial h6 {
  color: #003F62;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 2.2px;
}

@media (max-width: 425px) {
  #TopCate .nametop span {
    font-size: 8px;
    padding: 5px;
    margin: 5px;
    top: 5px;
  }

  .testimonial p {
    font-size: 14px;
}
.testimonial h6 {
  font-size: 20px;
  letter-spacing: 1.2px;
}

.testimonial {
  padding: 15px;
  margin: 10px;
}


}