.heading img {
  width: 40px;
  height: 40px;
}
.NewArrivals h3 {
  font-weight: 400;
}
.NewArrivals span {
  color: #EDA415 ;
  font-size: 15px;
  font-weight: 500;
}

.NewArrivals .box {
  text-align: center;
}

.NewArrivals .box img {  
  height: 150px;
  width: 150px;
  object-fit: contain;
  border-radius: 3px;
}

@media (max-width: 425px) {
  .NewArrivals span {
    font-size: 13px;
  }

  .arrival{
    gap: 0;
  }  

  .heading img {
    width: 30px;
    height: 30px;
  }
  
  .heading .heading-left h2{
    font-size: 20px;
  }
  
  .heading i {
    margin: 10px 5px;
  }
  
  

}  