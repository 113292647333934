#annocument{
    background: #000B01;
    padding: 40px 0;
    margin-top: -1px;
}

.img-box{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 20px;
    padding: 30px 0 20px;
}

.img-box img{
    height: 35px;
    width: auto;
}

.heading-common.head-annou h1::before{
    content: url('../../assests/bgs/1.png');    
    position: absolute;
    right: -50px;
    top: -90px;
    z-index: -1;
}

@media (max-width:320px) {
    .img-box{
        flex-direction: column;
    }

    .img-box img{
        height: 40px;
        width: auto;
    }
}