.wrapper {
  min-height: 700px;
  background: linear-gradient(180deg, rgba(220, 176, 0, 0.448) 10%, rgba(35, 49, 34, 0.392) 42.1%, rgba(0, 0, 0, 0.7) 97.38%), url("../../assests/banners/wrapper.jpg") center center no-repeat;
  background-size: cover;
  padding: 40px 0px;

}


.productImg img {
  width: 300px;
  height: 240px;
}

.headingMain {
  color: #000;
  text-align: center;
  font-size: 48px;
  margin: 20px auto;
}

@media (max-width: 768px) {
  .wrapper {
    height: auto;
  }  

}  

@media (max-width: 425px) {
  .productImg.imgHide{
    display: none;
  }

  .headingMain {
    font-size: 32px;
    margin: 15px auto;
}

  .productImg img {
    width: 260px;
    height: 200px;
}

}  