.Product_Data {
    padding: 50px 0;
    background-color: #000B01;
    margin-top: -1px;
}

.Product_Data .g_flex{
    gap: 40px;
}


.Product_Box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.Product_Box .img_Box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.70) 0%, rgba(164, 179, 162, 0.75) 29.17%, rgba(77, 107, 74, 0.80) 56.77%, #4D6B4A 100%);
    border-radius: 50%;
}

.Product_Box .img_Box img {
    width: auto;
    height: 60%;
}

.Product_heading {
    color: #FFF;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.48px;
    text-align: center;
}

.Product_num {
    color: #C0C3C6;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.36px;
    text-align: center;
}

@media (max-width: 768px) {
    .Product_Box .img_Box {
        width: 150px;
        height: 150px;
    }

    .Product_heading {
        font-size: 16px;
    }

    .Product_num {
        font-size: 12px;
    }

    .Product_Data .g_flex{
        gap: 30px;
    }
    
    

}

@media (max-width: 425px) {
    .Product_Box .img_Box {
        width: 120px;
        height: 120px;
    }

    .Product_heading {
        font-size: 13px;
    }

    .Product_num {
        font-size: 9px;
    }

    .Product_Data .g_flex{
        gap: 20px;
    }   
    

}