.product-filter {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .product-filter h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .product-filter .filter-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
  }
  
  .product-filter .select-box select{    
    padding: 10px 6px;
    border: 1px solid #ccc;
    border-radius: 3px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    width: 200px;  
  } 


  @media (max-width: 425px) {
    .product-filter .select-box select{          
        font-size: 14px;  
        width: 120px;      
      } 

  }
  